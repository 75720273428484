<template>
    <div class="blank">
        <el-card class="box-card" style="min-height: 100%">
        </el-card>
    </div>
</template>
<script>
export default {
    created() {
        // console.log('空白页')
        setTimeout(() => {
            this.$router.push({
                path: '/company/postJob',
            }).catch(() => {})
        }, 0)
    },
    mounted() {
    },
}
</script>
<style lang="scss" scoped>
    .blank{
        height: 100%;
    }
</style>